import React from "react";
import { Input, Spacer, Text } from "../../common";
import { useFormDataTextRequired } from "../../../shared/hooks/useFormData";

export const UserAdminForm = ({ formData, onChange }) => {
  return (
    <div className='d-flex'>
      <div className='flex-50'>
        <div className='d-flex align-center'>
          <Text color='secondary' type='md' noWrap>
            {useFormDataTextRequired(
              formData?.fullname?.label,
              formData?.fullname?.required,
            )}
          </Text>
          <Spacer left='auto' />
          <Input
            placeholder='Введите имя и фамилию'
            value={formData?.fullname?.value}
            required={formData?.fullname?.required}
            onChange={e => onChange(e.target.value, "fullname")}
          />
        </div>

        <Spacer top='2rem' />

        <div className='d-flex align-center'>
          <Text color='secondary' type='md' noWrap>
            {useFormDataTextRequired(
              formData?.username?.label,
              formData?.username?.required,
            )}
          </Text>
          <Spacer left='auto' />
          <Input
            type='tel'
            placeholder='Номер телефона'
            value={formData?.username?.value}
            required={formData?.username?.required}
            onChange={e => onChange(e.target.value, "username")}
          />
        </div>

        <Spacer top='2rem' />

        <div className='d-flex align-center'>
          <Text color='secondary' type='md' noWrap>
            {useFormDataTextRequired(
              formData?.email?.label,
              formData?.email?.required,
            )}
          </Text>
          <Spacer left='auto' />
          <Input
            type='email'
            placeholder='example@mail.com'
            value={formData?.email?.value}
            required={formData?.email?.required}
            onChange={e => onChange(e.target.value, "email")}
          />
        </div>
      </div>

      <Spacer left='4rem' />

      <div className='flex-50'></div>
    </div>
  );
};
