import { StoreThunkAction } from "../storeTypes";

import { selectAppLoader } from "./appSelectors";
import {
  APP_ERROR_CLEAR,
  APP_ERROR_SET,
  APP_LOADER_HIDE,
  APP_LOADER_SHOW,
  StoreActAppErrorSet,
  StoreAppErrorType,
  StoreAppErrorTypeLogin,
  StoreAppErrorTypeServer,
  StoreAppErrorTypeForm,
  StoreAppErrorTypeApp,
  StoreAppErrorStyleError,
  StoreAppErrorStyle,
  StoreAppError_SuccessServerResponse,
} from "./appTypes";

export const appLoaderShow = (): StoreThunkAction => (dispatch, getState) => {
  const appLoaderState = selectAppLoader(getState());
  if (!appLoaderState) {
    dispatch({ type: APP_LOADER_SHOW });
  }
};
export const appLoaderHide = (): StoreThunkAction => (dispatch, getState) => {
  const appLoaderState = selectAppLoader(getState());
  if (appLoaderState) {
    dispatch({ type: APP_LOADER_HIDE });
  }
};

const __appErrorTypeMessages = {
  [StoreAppErrorTypeLogin]: "Ошибка авторизации.",
  [StoreAppErrorTypeServer]: "Ошибка ответа от сервера.",
  [StoreAppErrorTypeForm]: "Ошибка заполнения формы.",
  [StoreAppErrorTypeApp]: "Внутренняя ошибка приложения Play4Sales.",
  [StoreAppError_SuccessServerResponse]: "Сообщение от сервера.",
};
export const appErrorSet = (
  msg: string,
  type: StoreAppErrorType = StoreAppErrorTypeServer,
  style: StoreAppErrorStyle = StoreAppErrorStyleError,
): StoreActAppErrorSet => ({
  type: APP_ERROR_SET,
  payload: {
    msg,
    type,
    style,
    msgTitle: __appErrorTypeMessages[type] ?? "Неизвестная ошибка.",
  },
});

export const appErrorClear = (): StoreThunkAction => (dispatch, getState) => {
  const appErrorState = getState()?.app?.error;
  if (appErrorState) {
    dispatch({ type: APP_ERROR_CLEAR });
  }
};
