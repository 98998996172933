import React, { useState } from "react";

import { Text, Spacer } from "../components/common";
import BackImage from "../assets/auth-back.png";
import Logo from "../assets/logo.svg";
import LoginCard from "../components/auth/LoginCard";
import ForgetCard from "../components/auth/ForgetCard";
import RecoveryCodeCard from "../components/auth/RecoveryCodeCard";
import RecoverCard from "../components/auth/RecoverCard";

const AuthPage = () => {
  const [recoveryCode, setRecoveryCode] = useState("");
  const onClearRecoveryCode = () => {
    if (recoveryCode?.length) {
      setRecoveryCode("");
    }
  };

  const [tab, setTab] = useState("login");
  const onLoginHandler = () => {
    setTab("login");
    onClearRecoveryCode();
  };
  const authCard = {
    login: { tab: <LoginCard onForget={() => setTab("forgetPassword")} /> },
    forgetPassword: {
      tab: (
        <ForgetCard
          onLogin={onLoginHandler}
          onNext={() => {
            onClearRecoveryCode();
            setTab("recoveryCode");
          }}
        />
      ),
    },
    recoveryCode: {
      tab: (
        <RecoveryCodeCard
          recoveryCode={recoveryCode}
          onAcceptCode={code => {
            setRecoveryCode(code);
            setTab("recoverPassword");
          }}
          onForgotCode={() => {
            onClearRecoveryCode();
            setTab("forgetPassword");
          }}
        />
      ),
    },
    recoverPassword: {
      tab: (
        <RecoverCard
          onLogin={onLoginHandler}
          onChangeCode={() => setTab("recoveryCode")}
          recoveryCode={recoveryCode}
        />
      ),
    },
  };

  return (
    <div className='auth-wrapper'>
      <div className='auth-card'>
        <div
          className='auth-left'
          style={{ backgroundImage: `url(${BackImage}` }}>
          <img className='logo' src={Logo} alt='Logo' />
          <Spacer top='8rem' />
          <Text type='lg' color='white'>
            Добро пожаловать в CRM систему приложения Play4Sales
          </Text>
        </div>

        <div className='auth-right'>{authCard[tab].tab}</div>
      </div>
    </div>
  );
};

export default AuthPage;
