import React, { useEffect, useState } from "react";
import {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
} from "../../../shared/hooks/useFormData";
import { Input, Select, Spacer, Text, Autocomplete } from "../../common";
import ListElement from "../ListElement";
import Search from "../Search";

export const UserAgentForm = ({
  formData,
  onChange,
  formOptions,
  onChangeOptions,
  locationMapperAdders,
  locationMapperRemovers,
}) => {
  const [searchLocationQuery, setSearchLocationQuery] = useState("");

  let queriedLocations = formOptions?.pharmacies;
  if (searchLocationQuery?.length && queriedLocations?.length) {
    const _textCompare = (a, b) =>
      a?.trim().toLowerCase().startsWith(b.trim().toLowerCase());
    queriedLocations = queriedLocations?.filter(
      o =>
        _textCompare(o?.primaryText, searchLocationQuery) ||
        _textCompare(o?.secondaryText, searchLocationQuery),
    );
  }
  if (formData?.pharmacies?.value?.length && queriedLocations?.length) {
    for (const p of formData?.pharmacies?.value) {
      const qlIdx = queriedLocations.findIndex(ql => ql.id === p.id);
      if (-1 < qlIdx) {
        queriedLocations.splice(qlIdx, 1);
      }
    }
  }

  const onChangeHandler = (value, valueName) => {
    onChange(value, valueName);
  };
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeHandler(opt, valueName);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className='d-flex'>
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.fullname?.label,
                formData?.fullname?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              placeholder='Введите имя и фамилию'
              value={formData?.fullname?.value}
              required={formData?.fullname?.required}
              onChange={e => onChangeHandler(e.target.value, "fullname")}
            />
          </div>
          <Spacer top='2rem' />
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.username?.label,
                formData?.username?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              type='tel'
              placeholder='Номер телефона'
              value={formData?.username?.value}
              required={formData?.username?.required}
              onChange={e => onChangeHandler(e.target.value, "username")}
            />
          </div>
        </div>
        <Spacer left='4rem' />
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.email?.label,
                formData?.email?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              type='email'
              placeholder='example@mail.com'
              value={formData?.email?.value}
              required={formData?.email?.required}
              onChange={e => onChangeHandler(e.target.value, "email")}
            />
          </div>
        </div>
      </div>
      <hr className='line' />
      <div className='d-flex'>
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.city?.label,
                formData?.city?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Autocomplete
              id='city'
              disabled={!formOptions?.cities?.length}
              placeholder='Выберите город'
              value={formData?.city?.value}
              onValueChange={option =>
                onChangeSelect(option, "city", formOptions?.cities)
              }
              required={formData?.city?.required}
              options={formOptions?.cities?.map(c => c.value)}
              getOptionLabel={option => option}
            />
          </div>
          <Spacer top='2rem' />
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.agentline?.label,
                formData?.agentline?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Select
              label='МП'
              disabled={!formOptions?.agentlines?.length}
              value={formData?.agentline?.value}
              required={formData?.agentline?.required}
              onChange={e =>
                onChangeSelect(
                  e.target.value,
                  "agentline",
                  formOptions.agentlines,
                )
              }
              variants={formOptions?.agentlines}
            />
          </div>
        </div>
        <Spacer left='4rem' />
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.command?.label,
                formData?.command?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Autocomplete
              id='command'
              placeholder='Выберите команду'
              disabled={!formOptions?.commands?.length}
              value={formData?.command?.value}
              onValueChange={option =>
                onChangeSelect(option, "command", formOptions?.commands)
              }
              required={formData?.command?.required}
              options={formOptions?.commands?.map(c => c.value)}
              getOptionLabel={option => option}
            />
          </div>
        </div>
      </div>
      <hr className='line' />
      <Text color='secondary' type='md' noWrap>
        {useFormDataTextRequired(
          formData?.pharmacies?.label,
          formData?.pharmacies?.required,
        ) + ` (${queriedLocations?.length ?? 0})`}
      </Text>

      <Spacer top='2rem' />

      <div className='d-flex'>
        <div className='flex-50'>
          <Search
            disabled={!formOptions?.pharmacies?.length}
            required={false}
            options={locationMapperAdders(queriedLocations)}
            searchQuery={searchLocationQuery}
            onChangeQuery={q => setSearchLocationQuery(q)}
          />
        </div>

        <Spacer left='3rem' />

        <div className='flex-50'>
          {formData?.pharmacies?.value?.length
            ? locationMapperRemovers(
                formData?.pharmacies?.value,
              )?.map((el, index) => (
                <ListElement
                  key={index}
                  primaryText={el.primaryText}
                  secondaryText={el.secondaryText}
                  actionSlot={el.action}
                />
              ))
            : null}
        </div>
      </div>
    </>
  );
};
