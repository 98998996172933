import { useState } from "react";
import APIService, { API_DATA_ENTITIES } from "../../../services/api.service";
import {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
  useFormData_UserRolePharmacy,
} from "../../../shared/hooks/useFormData";
import { Autocomplete, Icon, Input, Select, Spacer, Text } from "../../common";
import ListElement from "../ListElement";
import Search from "../Search";

export const UserPharmacyForm = ({
  formData,
  onChange,
  formOptions,
  onChangeOptions,
  agentMapperAdders,
  agentMapperRemovers,
  onChangeFormData,
  isNew,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  let queriedAgents = formOptions?.agents;
  if (searchQuery?.length && queriedAgents?.length) {
    const _textCompare = (a, b) =>
      a?.trim().toLowerCase().startsWith(b.trim().toLowerCase());
    queriedAgents = queriedAgents?.filter(
      o =>
        _textCompare(o?.primaryText, searchQuery) ||
        _textCompare(o?.secondaryText, searchQuery),
    );
  }
  if (formData?.agents?.value?.length && queriedAgents?.length) {
    for (const a of formData?.agents?.value) {
      const aIdx = queriedAgents?.findIndex(_a => _a.id === a.id);
      if (-1 < aIdx) {
        queriedAgents?.splice(aIdx, 1);
      }
    }
  }

  const onChangeHandler = (value, valueName) => {
    onChange(value, valueName);
  };
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeHandler(opt, valueName);
  };

  const onFetchBINData = async bin => {
    const entityData = await APIService.getData(
      API_DATA_ENTITIES,
      0,
      100,
      `bin=${bin}`,
    ).then(
      resp => resp.data[0],
      err => {},
    );

    if (!entityData) {
      onChangeFormData(
        {
          ...formData,
          ...{
            entityID: undefined,
          },
        },
        useFormData_UserRolePharmacy,
      );
      return;
    }

    let _data = ["entityname", "entityphone", "contract"];
    let _newEntityData = {
      entityID: entityData?.id,
    };

    for (const _dataName of _data) {
      switch (_dataName) {
        default:
          _newEntityData[_dataName] = {
            ...formData[_dataName],
            value: entityData[_dataName],
          };
          break;
      }
    }

    onChangeFormData(
      { ...formData, ..._newEntityData },
      useFormData_UserRolePharmacy,
    );
  };

  // const isEntityDataDisabled = !!formData?.entityID || !isNew;
  const isEntityDataDisabled = false;

  return (
    <>
      <div className='d-flex'>
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.fullname?.label,
                formData?.fullname?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              placeholder='Введите имя и фамилию'
              required={formData?.fullname?.required}
              value={formData?.fullname?.value}
              onChange={e => onChangeHandler(e.target.value, "fullname")}
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.username?.label,
                formData?.username?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              type='tel'
              placeholder='Номер телефона'
              required={formData?.username?.required}
              value={formData?.username?.value}
              onChange={e => onChangeHandler(e.target.value, "username")}
            />
          </div>
        </div>

        <Spacer left='4rem' />

        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.email?.label,
                formData?.email?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              type='email'
              placeholder='example@mail.com'
              required={formData?.email?.required}
              value={formData?.email?.value}
              onChange={e => onChangeHandler(e.target.value, "email")}
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.address?.label,
                formData?.address?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              placeholder='Фактический адрес'
              required={formData?.address?.required}
              value={formData?.address?.value}
              onChange={e => onChangeHandler(e.target.value, "address")}
            />
          </div>
        </div>
      </div>

      <hr className='line' />

      <div className='d-flex'>
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.city?.label,
                formData?.city?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Autocomplete
              id='city'
              disabled={!formOptions?.cities?.length}
              placeholder='Выберите город'
              value={formData?.city?.value}
              onValueChange={option =>
                onChangeSelect(option, "city", formOptions?.cities)
              }
              required={formData?.city?.required}
              options={formOptions?.cities?.map(c => c.value)}
              getOptionLabel={option => option}
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.entityname?.label,
                formData?.entityname?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              disabled={isEntityDataDisabled}
              placeholder='Юридическое лицо'
              required={formData?.entityname?.required}
              value={formData?.entityname?.value}
              onChange={e => onChangeHandler(e.target.value, "entityname")}
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex'>
            <div className='flex-50 d-flex align-center'>
              <Text color='secondary' type='md' noWrap>
                {useFormDataTextRequired(
                  formData?.bin?.label,
                  formData?.bin?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Input
                disabled={isEntityDataDisabled}
                onBlur={e => onFetchBINData(e.target.value)}
                style={{ maxWidth: "25.4rem" }}
                placeholder='0000'
                required={formData?.bin?.required}
                value={formData?.bin?.value}
                onChange={e => onChangeHandler(e.target.value, "bin")}
              />
            </div>

            <Spacer left='3.5rem' />

            <div className='flex-50 d-flex align-center'>
              <Text color='secondary' type='md' noWrap>
                {useFormDataTextRequired(
                  formData?.contract?.label,
                  formData?.contract?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Input
                disabled={isEntityDataDisabled}
                style={{ maxWidth: "15rem" }}
                placeholder='492145'
                required={formData?.contract?.required}
                value={formData?.contract?.value}
                onChange={e => onChangeHandler(e.target.value, "contract")}
              />
            </div>
          </div>
        </div>

        <Spacer left='4rem' />
        <div className='flex-50'>
          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.pharmacyname?.label,
                formData?.pharmacyname?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              placeholder='Введите название аптеки'
              required={formData?.pharmacyname?.required}
              value={formData?.pharmacyname?.value}
              onChange={e => onChangeHandler(e.target.value, "pharmacyname")}
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.category?.label,
                formData?.category?.required,
              )}
            </Text>
            <Spacer left='5rem' />
            <Select
              style={{ maxWidth: "10rem" }}
              disabled={!formOptions?.categories?.length}
              variants={formOptions?.categories}
              required={formData?.category?.required}
              value={formData?.category?.value}
              onChange={e =>
                onChangeSelect(
                  e.target.value,
                  "category",
                  formOptions?.categories,
                )
              }
            />
          </div>

          <Spacer top='3rem' />

          <div className='d-flex align-center'>
            <Text color='secondary' type='md' noWrap>
              {useFormDataTextRequired(
                formData?.entityphone?.label,
                formData?.entityphone?.required,
              )}
            </Text>
            <Spacer left='auto' />
            <Input
              disabled={isEntityDataDisabled}
              placeholder='Номер телефона'
              required={formData?.entityphone?.required}
              value={formData?.entityphone?.value}
              onChange={e => onChangeHandler(e.target.value, "entityphone")}
            />
          </div>
        </div>
      </div>

      <hr className='line' />

      <Text color='secondary' type='md' noWrap>
        {`${useFormDataTextRequired(
          formData?.agents?.label,
          formData?.agents?.required,
        )} (${formData?.agents?.value?.length ?? 0}/3)`}
      </Text>

      <Spacer top='3rem' />

      <div className='d-flex'>
        <div className='flex-50'>
          <Search
            disabled={!formOptions?.agents?.length}
            required={false}
            options={agentMapperAdders(queriedAgents)}
            searchQuery={searchQuery}
            onChangeQuery={q => setSearchQuery(q)}
          />
        </div>

        <Spacer left='3rem' />

        <div className='flex-50'>
          {formData?.agents?.value?.length
            ? agentMapperRemovers(formData?.agents?.value)?.map((el, index) => (
                <ListElement
                  key={index}
                  primaryText={el.primaryText}
                  secondaryText={el.secondaryText}
                  actionSlot={el.action}
                />
              ))
            : null}
        </div>
      </div>
    </>
  );
};
